import React, { Component } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';
import { withRouter } from 'react-router-dom'; // to access history
import css from './DistanceFilter.module.css';
import routeConfiguration from '../../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../../util/routes';

class DistanceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDistance: this.props.urlQueryParams?.distance || null,
    };
  }

  handleSelectionChange = event => {
    const value = event.target.value;
    this.setState({ selectedDistance: value });
    const { history, queryParamNames, urlQueryParams } = this.props;
    const urlParam = queryParamNames[0];
    history.push(`?${urlParam}=${value}`);

    const searchParams = {
      ...urlQueryParams,
      [urlParam]: value,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      contentPlacementOffset,
      intl,
      showAsPopup,
      urlQueryParams,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const distances = [10, 20, 50, 100, 200, 500];
    const radioOptions = distances.map(distance => (
      <div key={distance} className={css.radioWrapper}>
        <input
          type="radio"
          id={`distance-${distance}`}
          name={name}
          value={distance}
          checked={this.state.selectedDistance == distance}
          onChange={this.handleSelectionChange}
          className={css.customRadio}
        />
        <label htmlFor={`distance-${distance}`} className={css.radioLabel}>
          {intl.formatMessage({ id: `LandingSearchForm.${distance}` })}
        </label>
      </div>
    ));

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={label}
        isSelected={this.state.selectedDistance !== null}
        id={`${id}.popup`}
        showAsPopup
        hideReset
        labelMaxWidth={250}
        contentPlacementOffset={contentPlacementOffset}
        {...rest}
      >
        {radioOptions}
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={label}
        isSelected={this.state.selectedDistance !== null}
        id={`${id}.plain`}
        liveEdit
        hideReset
        contentPlacementOffset={contentPlacementOffset}
        {...rest}
      >
        <fieldset className={css.fieldPlain}>{radioOptions}</fieldset>
      </FilterPlain>
    );
  }
}

DistanceFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: string.isRequired,
  contentPlacementOffset: number,
  intl: intlShape.isRequired,
};

export default withRouter(injectIntl(DistanceFilter));
