import React from 'react';

// utils
import { SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM, SCHEMA_TYPE_LONG } from '../../util/types';
import { convertCategoriesToSelectTreeOptions, constructQueryParamName } from '../../util/search';

// component imports
import SelectSingleFilter from './SelectSingleFilter/SelectSingleFilter';
import SelectMultipleFilter from './SelectMultipleFilter/SelectMultipleFilter';
import BookingDateRangeFilter from './BookingDateRangeFilter/BookingDateRangeFilter';
import KeywordFilter from './KeywordFilter/KeywordFilter';
import PriceFilter from './PriceFilter/PriceFilter';
import LocationFilter from './LocationFilter/LocationFilter';
import DistanceFilter from './DistanceFilter/DistanceFilter';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createResourceLocatorString } from '../../util/routes';
import IntegerRangeFilter from './IntegerRangeFilter/IntegerRangeFilter';

const { LatLng, LatLngBounds } = sdkTypes;

// Helper: get enumOptions in a format that works as query parameter
const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
const FilterComponent = props => {
  const {
    idPrefix,
    config,
    urlQueryParams,
    initialValues,
    getHandleChangedValueFn,
    listingCategories,
    marketplaceCurrency,
    intl,
    ...rest
  } = props;

  const location = useLocation();
  const parsedSearch = parse(location.search);

  const address = parsedSearch?.address;
  const origin = parsedSearch?.origin;
  const bounds = parsedSearch?.bounds;

  const topbarSearcInitialValues = () => {
    return {
      location: {
        search: address,
        selectedPlace: { address, origin, bounds },
      },
    };
  };

  const routeConfiguration = useRouteConfiguration();

  const handleLocationSubmit = values => {
    const {
      dates = null,
      distance = null,
      keywords = null,
      location = null,
      tournamentdetails = null,
      game = null,
      ...rest
    } = values || {};

    // Initialize searchQuery with potentially present, non-null values
    const searchQuery = {};
    if (dates) searchQuery.dates = dates;
    if (distance) searchQuery.distance = distance;
    if (keywords) searchQuery.keywords = keywords;
    const { search = 'Deutschland', selectedPlace } = location || {};

    const defaultBounds = new LatLngBounds(
      new LatLng(55.1286491, 15.041832),
      new LatLng(47.270238, 5.866315)
    );

    const defaultOrigin = new LatLng(51.1334813439932, 10.0183432948567);

    const { origin = defaultOrigin, bounds = defaultBounds } = selectedPlace || {};
    const originMaybe = isOriginInUse(config) ? { origin } : {};

    Object.assign(searchQuery, { ...originMaybe, address: search, bounds });

    if (tournamentdetails) searchQuery['pub_tournamentdetails'] = tournamentdetails;

    // Add prefixed keys from `rest`
    Object.keys(rest).forEach(key => {
      searchQuery[`pub_${key}`] = rest[key];
    });

    // Ensure game key is prefixed properly
    if (game) {
      searchQuery['pub_game'] = game;
    }

    // Modify `searchQuery` for rules keys as per the new requirements
    Object.keys(searchQuery).forEach(key => {
      if (key.includes('rule')) {
        // Check if key contains "rule"
        const val = searchQuery[key];
        if (Array.isArray(val)) {
          // Ensure it is an array
          if (val.length > 1) {
            delete searchQuery[key]; // Remove from query if array length is greater than 1
          } else if (val.length === 1) {
            searchQuery[key] = val[0]; // Assign the first element if array length is exactly 1
          }
        }
      }
    });

    const isDefault = searchQuery?.address === 'Deutschland';

    if (isDefault) {
      delete searchQuery.distance;
    }

    if (typeof window !== 'undefined') {
      window.location.href = createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        {},
        searchQuery
      );
    }
  };

  const initialSearchFormValues = topbarSearcInitialValues();

  // Note: config can be either
  // - listingFields config or
  // - default filter config
  // They both have 'key' and 'schemaType' included.
  const { key, schemaType } = config;
  const { liveEdit, showAsPopup } = rest;

  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${key.toLowerCase()}`;
  const name = key.replace(/\s+/g, '-');

  // Default filters: price, keywords, dates
  // eslint-disable-next-line default-case
  switch (schemaType) {
    case 'category': {
      const { scope, isNestedEnum, nestedParams } = config;
      const queryParamNames = nestedParams?.map(p => constructQueryParamName(p, scope));
      return (
        <SelectSingleFilter
          id={componentId}
          name={key}
          label={intl.formatMessage({ id: 'FilterComponent.categoryLabel' })}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          options={convertCategoriesToSelectTreeOptions(listingCategories)}
          isNestedEnum={isNestedEnum}
          {...rest}
        />
      );
    }
    case 'price': {
      const { min, max, step } = config;
      return (
        <PriceFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.priceLabel' })}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          min={min}
          max={max}
          step={step}
          marketplaceCurrency={marketplaceCurrency}
          {...rest}
        />
      );
    }
    case 'location':
      return (
        <LocationFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.locationLabel' })}
          name={name}
          config={config}
          queryParamNames={[key]}
          initialSearchFormValues={initialSearchFormValues}
          location={location}
          handleLocationSubmit={handleLocationSubmit}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...rest}
        />
      );
    case 'distance':
      return (
        <DistanceFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.distanceLabel' })}
          name={name}
          location={location}
          urlQueryParams={urlQueryParams}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...rest}
        />
      );
    case 'keywords':
      return (
        <KeywordFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.keywordsLabel' })}
          name={name}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...rest}
        />
      );
    case 'dates': {
      const { dateRangeMode } = config;
      const isNightlyMode = dateRangeMode === 'night';
      return (
        <BookingDateRangeFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.datesLabel' })}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          minimumNights={isNightlyMode ? 1 : 0}
          {...rest}
        />
      );
    }
  }

  // Custom extended data filters
  switch (schemaType) {
    case SCHEMA_TYPE_ENUM: {
      const { scope, enumOptions, filterConfig = {} } = config;
      const { label, filterType } = filterConfig;
      const queryParamNames = [constructQueryParamName(key, scope)];
      return filterType === 'SelectSingleFilter' ? (
        <SelectSingleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          options={enumOptions}
          isNestedEnum={false}
          {...rest}
        />
      ) : (
        <SelectMultipleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          options={enumOptions}
          schemaType={schemaType}
          {...rest}
        />
      );
    }
    case SCHEMA_TYPE_MULTI_ENUM: {
      const { scope, enumOptions, filterConfig = {} } = config;
      const { label, searchMode } = filterConfig;
      const queryParamNames = [constructQueryParamName(key, scope)];
      return (
        <SelectMultipleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          options={enumOptions}
          schemaType={schemaType}
          searchMode={searchMode}
          {...rest}
        />
      );
    }
    case SCHEMA_TYPE_LONG: {
      const { minimum, maximum, scope, step, filterConfig = {} } = config;
      const { label } = filterConfig;
      const queryParamNames = [constructQueryParamName(key, scope)];
      return (
        <IntegerRangeFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          min={minimum}
          max={maximum}
          step={step}
          {...rest}
        />
      );
    }
    default:
      return null;
  }
};

export default FilterComponent;
